.pnfMain {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    color: white;
    width: 100vw;
    height: 100vh;
}

.img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-image:linear-gradient(rgba(44, 90, 167, 0.5),rgba(44, 90, 167, 0.5)) , url(../../assets/pnf.jpg);
}

.pnf {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pnf > h1 {
  font-weight: 400;
  font-size: 45px;
  margin-bottom: 25px;
}

.pnf > h2 {
  font-weight: 300;
  font-size: 25px;
  margin-bottom: 15px;
}

.pnf > h3 {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 10px;
}