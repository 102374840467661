.legal-page {
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  text-align: justify;
  color: #333333;
}

.legal-page h1 {
  color: #2c5aa7;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.legal-page p {
  color: #444444;
  font-size: 1.1em;
  margin-bottom: 15px;
}

.legal-page strong {
  color: #2c5aa7;
}
