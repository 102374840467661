.comments {
  margin-top: 30px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.comment-form-container {
  margin: 0 auto;
  padding: 0;
}

h2 {
  text-align: center;
  color: #333;
}

.form-group {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input[type="text"],
input[type="email"] {
  width: 93%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

textarea {
  width: 98%;
  height: 150px; /* Set a specific height */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #2c5aa7;
  outline: none;
}

textarea {
  max-width: 100%; /* Ensures it doesn't exceed the form's width */
  height: 80px; /* Set a specific height */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.submit-button {
  width: 50%;
  padding: 10px;
  background-color: #2c5aa7;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #2c5aa7;
}

.submit-button:active {
  background-color: #2c5aa7;
}

.comments-section {
  max-width: 600px;
  margin: 20px auto;
  border-bottom: 1px black solid;
}

.comments-section h3 {
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.comment {
  border-bottom: 1px solid #fbf8f8;
  padding: 15px 0;
}

.comment:last-child {
  border-bottom: none; /* Remove border for the last comment */
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.comment-author {
  font-weight: bold;
  color: #007bff;
}

.comment-date {
  color: #888;
  font-size: 0.9em;
}

.comment-text {
  color: #555;
  line-height: 1.5;
}

.d-flex {
  width: 100%;
  display: flex;
  gap: 30px;
}
