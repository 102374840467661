.main {
  display: flex;
  flex-direction: column;
  width: 98vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  gap: 2%;
  min-height: 90vh;
  max-height: fit-content;
}

.mainBody {
  display: flex;
  width: 100vw;
  max-width: 1200px;

  justify-content: start;
}

.blogSection {
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 2px;
  width: 70%;
  margin-top: 30px;
}

.searchSection {
  display: flex;
  align-items: center;
  gap: 0 !important;
  width: 100%;
}
.searchSection .searchInput {
  width: 350px;
  height: 30px;
  padding: 3px 10px 3px 10px;
  background-color: rgb(241, 236, 236);
  color: black;
  border-radius: 25px 0px 0px 25px;
  border: 0;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.searchSection input:focus {
  outline: none;
}

.searchSection .searchButton {
  height: 36px;
  padding: 3px 15px 3px 15px;
  border-radius: 0px 25px 25px 0px;
  box-shadow: none !important;
  cursor: pointer;
  border: 0;
}

.side {
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 247, 245);
  color: #272626;
  padding: 20px;
  max-height: 80vh;
  margin-top: 20px;
}

.blogs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog {
  display: flex;
  width: 95%;
  height: fit-content;
  border: 0;
  border-radius: 10px !important;
  margin-bottom: 15px;
  gap: 10px;
  text-align: left;
}

.acpP {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tImg {
  width: 100%;
  height: 450px;
}

.tImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bInfo {
  background-color: rgb(248, 247, 245);
  padding: 15px;
  flex-basis: 50%;

}

.bAuthor {
  font-size: 16px;
  font-weight: 400px;
}

.blog > a {
  color: inherit;
  text-decoration: none;
  display: flex;
  width: 100%;
}


.dot {
    width: 7px;
    height: 7px;
    background: #080808;
    border-radius: 50%;
}

.acpP {
    display: flex;
    gap: 10px;
    align-items: center;
}

.blogThumb {
  flex-basis: 50%;
  width: 50%;
  height: 250px;
  object-fit: cover;
  align-self: center;
}

.blog:nth-child(odd) {
  /* background-color: #2C5AA7; */
  color: rgb(15, 15, 15);
}

.blog:nth-child(even) {
  color: #2c5aa7;
}

.authorCard {
  display: flex;
  gap: 30px;
  align-items: center;
}

.authorCard > img {
  height: 45px;
  width: 45px;
  border: 1px solid grey;
  border-radius: 50%;
}

.tImgsingle {
  height: 300px !important;
}

.content {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 5px auto;
  text-transform: capitalize;
  color: black;
}

.separator {
  height: 2px !important;
  width: 100%;
  background-color: #232323;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tpost {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  font-size: 30px;
  color: #2c5aa7;
  font-weight: 800;
}

.classOne {
  font-size: 30px;
}

.pContent {
  font-size: 20px;
  font-weight: 300 !important;
  line-height: 25px;
}

.authorCard {
  display: flex;
  color: #2c5aa7;
  gap: 50px;
}

.tImgsingle {
  height: 300px !important;
}

.bInfo > .title {
  font-size: 18px;
  font-weight: 600;
  color: #2c5aa7;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  color: black;
}

.bgLoader {
  display: flex;
  width: 100vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #2c5aa7,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #2c5aa7, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #2c5aa7,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #2c5aa7,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #2c5aa7,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #2c5aa7,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #2c5aa7,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #2c5aa7;
  }
}

.roll {
  margin-top: auto;
  margin-bottom: auto;
}

.singlePost {
  display: flex;
  max-width: 1100px;
  margin: 10px auto;
  gap: 25px;
  width: 98vw;
}

.singlePostMain {
  width: 75%;
}

.singlePostMain .title {
  font-size: 25px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 10px;
}

.singlePostMain .image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border: 1px #222324 solid;
  border-radius: 5px;
  box-shadow: #272626 1px 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
}
.singlePostMain .content {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.singlePostAuthor {
  width: 25%;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: sticky;
  padding: 20px;
}

.singlePostAuthor:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.singlePostAuthor .image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px black solid;
}

.carousel {
  position: relative;
  padding: 15px;
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin: auto;
  text-align: center;
  background-color: #8caaf5;
  border-radius: 5px;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.carousel-item img {
  width: 100%;
  height: 100%;
}

.carousel-item .bInfo {
  position: absolute;
  top: 60%;
  left: 20%;
  color: black;
  transform: translate(-50%, -50%);
  background: inherit;
}

.carousel-item .bInfo .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c5aa7;
}

.carousel-item .bInfo .content {
  font-size: 16px;
}

.carousel-item.active {
  left: 0;
  opacity: 1;
}

.hpost {
  font-size: 24px;
  text-decoration: underline #2c5aa7;
  color: #2c5aa7;
  margin-bottom: 20px;
}

.hpostContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding: 10px;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #2c5aa7;
}

.hpostContent img {
  width: 60px !important;
  height: 60px !important;
}

.hpostImg {
  width: 60px !important;
  height: 60px !important;
}

.hpostInfo .title {
  color: #2c5aa7;
  font-size: 20px;
}

.hpostInfo .content {
  color: black;
  font-size: 15px;
}

.paginationFlex {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  margin-right: 40px;
}

.paginationFlex h2 span {
  font-size: 24px;
  color: black;
}

li a {
  background-color: #2c5aa7 !important;
}

.notFound {
  display: grid;
  margin-top: 40px;
  place-items: center;
  font-size: 30px;
  width: 50vw;
}

@media only screen and (max-width: 1000px) {
  .side {
    display: none;
  }

  .blogs {
    width: 95%;
    margin: 5px auto;
  }
  .blogSection {
    width: 100%;
  }

  .searchSection {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .blog {
    width: 98%;
    /* margin: 10px auto; */
    /* max-width: 450px; */
  }
  .singlePost {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
  }
  .singlePost .singlePostMain {
    width: 92%;
  }

  .singlePostAuthor {
    width: 85%;
  }
}

@media only screen and (max-width: 550px) {
  .singlePost {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
  }
  .singlePost .singlePostMain {
    width: 92%;
  }

  .singlePostAuthor {
    width: 85%;
  }
}
