* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.home {
  display: flex;
  flex-direction: column;
  width: 98vw;
  max-width: 1200px;
  /* height: 500px; */
  margin: 0px auto;
}

.hero {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50x;
}

.text {
  font-family: Inter;
  font-size: 38px;
  font-weight: 700;
  line-height: 58.09px;
  text-align: left;
  color: black;
}

.button {
  font-family: Inter;
  font-size: 38px;
  font-weight: 700;
  line-height: 58.09px;
  text-align: left;
  color: #ffff;
  background-color: #2c5aa7;
  padding: 5px;
  border-radius: 5px;
  margin-left: 400px;
  margin-bottom: 100px;
}

.services {
  display: flex;
  flex-wrap: wrap;
}

.service {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border: 1px solid #b1aeae; */
  /* border-radius: 5px; */
  width: 46%;
  margin: 10px auto;
  align-items: center;
  padding: 15px;
  text-align: center;
}

.service > img {
  width: 20%;
  height: auto;
}

.service:nth-child(1) {
  background: linear-gradient(90deg, #6d95fc, #2c5aa7);
}

.service:nth-child(2) {
  background: linear-gradient(180deg, #6d95fc, #2c5aa7);
}

.service:nth-child(3) {
  background: linear-gradient(360deg, #6d95fc, #2c5aa7);
}

.service:nth-child(4) {
  background: linear-gradient(270deg, #6d95fc, #2c5aa7);
}

/* .sAlt {
  width: 50%;
  height: 50%;
  margin: 0px;
} */

/* .service:hover {
    background-color: #95C1E6;
    border: 1px solid #95C1E6;
} */

.sImage > img {
  width: 300px;
  height: 250px;
  object-fit: cover;
  margin-top: 10px;
}

.sDescription {
  width: 70%;
  margin-bottom: 10px;
  text-align: center;
}

.header {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  font-family: Inter;
  margin-top: 50px;
  margin-bottom: 10px;
}

.header2 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  font-family: Inter;
  margin-bottom: 20px;
}

/* slider css */

.slideMain {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 98vw;
  gap: 10px;
  height: fit-content;
}

.slider-container {
  position: relative;
  max-width: 1200px;
  width: 98vw;
  margin: auto;
  overflow: hidden;
  height: 500px;
  border: 2px solid #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.before-slide,
.after-slide {
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  color: dark;
  height: 400px;
}

.slider {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid #ddd;
  padding-bottom: 20px;
}

.slider img {
  width: 100%;
}

.slider:hover {
  cursor: pointer;
}

.slide {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  opacity: 0;
  position: absolute;
  transition: opacity 1s, transform 1s;
}

.value > a {
  text-decoration: none;
  color: #080808;
}

.slide.active {
  opacity: 1;
  transform: translateX(0);
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 2em;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

Active slide .slide.active {
  opacity: 1;
  position: relative;
}

/* Navigation buttons */
.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  font-size: 24px;
  transition: background-color 0.3s;
}

/* Left navigation button */
.prev {
  left: 10px;
}

/* Right navigation button */
.next {
  right: 10px;
}

/* Hover effects for buttons */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.nav-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 50px;
}

.nav-dot {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #080808;
  /* border-radius: 50%; */
  padding: 2px;
  border: 2px solid white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-dot.active {
  border: 2px solid black;
}

@media only screen and (max-width: 900px) {
  .text,
  .button {
    font-size: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .text,
  .button {
    font-size: 25px;
  }

  .sImage > img {
    width: 100%;
  }

  .button {
    margin-left: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .service {
    width: 90%;
  }

  .sImage {
    width: 90%;
  }

  .text,
  .button {
    font-size: 20px;
  }

  .button {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 450px) {
  .text,
  .button {
    font-size: 15px;
  }

  .button {
    margin-left: 100px;
  }
}

.homeB {
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
}

.hero,
.about,
.services,
.stats,
.projects,
.exp,
.testimonials,
.footerB {
  width: 96vw;
  max-width: 1200px;
  margin: auto;
  padding: 50px 2px;
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.hero {
  height: 600px;
  position: relative;
}

.htext {
  width: 50%;
  height: 600px;
  text-align: left;
  justify-content: space-around;
  z-index: 1;
  background: #ffffff;
  padding-right: 60px;
}

.himage {
  width: 50%;
  z-index: -1;
  display: flex;
  position: absolute;
  right: 0;
  top: 50px;
}
.himage > img {
  width: 100%;
  height: auto;
  left: auto;
}

.accent {
  font-size: 21px;
  font-weight: 500;
  line-height: 31.5px;
  letter-spacing: 0.145em;
  text-transform: uppercase;
  padding: 5px;
  border: 1px solid grey;
  width: fit-content;
}

.headerA {
  font-size: 70px;
  font-weight: 700;
  line-height: 89.19px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  text-align: start;
}

.divider {
  height: 5px !important;
  width: 0vw !important;
  background-color: #2c5aa7 !important;
  color: #2c5aa7;
}

.text {
  font-size: 21px;
  font-weight: 300;
  line-height: 31.5px;
}

.btn {
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  letter-spacing: 0.145em;
  text-transform: uppercase;
  padding: 5px;
  width: fit-content;
  border-radius: 3px;
  color: #ffffff;
  background-color: #2c5aa7;
}

.btn > a {
  text-decoration: none;
  color: #ffffff;
}

.acc {
  color: #2c5aa7;
}

.about {
  padding-top: 0px;
}

.hero {
  padding-bottom: 0px;
}

.aboutA,
.aboutB {
  width: 50%;
}

.aboutA > img {
  width: 100%;
  height: auto;
}

.headerB {
  font-size: 48px;
  font-weight: 700;
  line-height: 59.76px;
  text-transform: capitalize;
}

.sub {
  font-size: 14px;
  font-weight: 400;
  line-height: 25.5px;
  letter-spacing: 0.005em;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
}

.services {
  flex-direction: column;
}

.servicesA,
.servicesB {
  width: 100%;
  text-align: center;
  margin: 5px auto;
}

.servicesB {
  display: flex;
  flex-wrap: wrap;
}

.projects {
  flex-direction: column;
  text-align: center;
}

.stats {
  justify-content: space-around;
  background: linear-gradient(270deg, #6d95fc, #2c5aa7);
  flex-wrap: wrap;
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}

.stat > img {
  width: 70px;
  height: 70px;
}

.sValue {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #ffff;
}

.sName {
  font-size: 15px;
  font-weight: 400;
  line-height: 25.73px;
  letter-spacing: 0.04em;
  text-align: center;
}

.contact {
  width: 96vw;
  max-width: 1200px;
  margin: auto;
  display: flex;
  padding: 50px 2px;
  align-items: start;
}

.exp1,
.exp2,
.contactA,
.contactB {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.contact .contactA .headerB {
  text-align: start !important;
}

.bullet {
  display: flex;
  gap: 4px;
}

.testimonials {
  position: relative;
  width: 100%;
  overflow: hidden;
  align-items: stretch;
  align-items: center;
}

.tbg-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Ensure this is below the testi div */
}

.tbg {
  width: 50%;
  display: flex;
  align-items: center;
  align-content: center;
}

.tbg > img {
  width: 100%;
  height: auto;
}

.testi {
  width: 50%;
  margin: 0px auto;
  position: relative;
  z-index: 2;
  background-color: rgb(212, 207, 207);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 5px;
  justify-content: space-around;
  /* top: -50px; */
}

.testi > .btn {
  margin-top: 10px;
}

.tcard {
  width: 80%;
  border-radius: 5px;
  border: 10px bold rgb(100, 100, 224);
  background: linear-gradient(180deg, #bcc6df, #aac5f5);
  padding: 7px;
}

.client {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cimage {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.cimage > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.cname {
  font-size: 21px;
  font-weight: 700;
  line-height: 34.96px;
  letter-spacing: 0.04em;
}

.corg {
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 23.31px;
  letter-spacing: 0.04em;
  color: #064af7;
}

.group > img {
  height: 15px;
}

.ccard {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 20px;
}

.ccard > img {
  height: 30px;
}

.item {
  font-size: 21px;
  font-weight: 700;
  line-height: 34.96px;
  letter-spacing: 0.04em;
}

.value {
  font-size: 16px;
  font-weight: 400;
  line-height: 26.64px;
  letter-spacing: 0.04em;
}

.footerB {
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.footerB > img {
  width: 200px;
  height: auto;
}

.socials,
.links {
  display: flex;
  gap: 20px;
}

.socials {
  margin-bottom: 30px;
}

.aboutHome {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.classOne {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.classTwo {
  width: 98vw;
  max-width: 1000px;
  margin: auto;
  line-height: 32px;
}
/* .contactB > .form {
  width: 100%;
}

.formInput {
 width: 90% !important;
} */

.formm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formm > div > textarea {
  height: 150px;
  background-color: #95c1e6;
  border-radius: 5px;
  margin-left: 5px;
}

@media only screen and (max-width: 1180px) {
  .headerA {
    font-size: 60px;
    line-height: 79.19px;
  }

  .text {
    font-size: 18px;
    line-height: 28.5px;
  }

  .accent {
    font-size: 19px;
  }

  .btn {
    font-size: 15px;
    line-height: 20.5px;
  }

  .headerB {
    font-size: 40px;
    line-height: 49.76px;
  }

  .hero {
    height: 550px;
  }

  .htext {
    height: 100%;
    /* margin-top: 1%; */
  }

  .stat > img {
    width: 50%;
    height: auto;
  }

  .sValue {
    font-size: 34px;
  }

  .sName {
    font-size: 17px;
  }

  /* .formInput {
    width: 70% !important;
   } */

  .testi > .headerB {
    text-align: center;
    font-size: 32px;
    line-height: 35.76px;
  }

  .tcard {
    width: 80%;
    border-radius: 5px;
    border: 10px bold rgb(100, 100, 224);
    background: linear-gradient(180deg, #bcc6df, #aac5f5);
    padding: 7px;
  }

  .tcard > .text {
    font-size: 15px;
    line-height: 22.5px;
  }

  .cimage {
    height: 70px;
    width: 70px;
    border-radius: 50%;
  }
  .cimage > img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .hero,
  .about,
  .services,
  .stats,
  .projects,
  .exp,
  .testimonials,
  .contact,
  .footerB {
    width: 96vw;
    max-width: 1200px;
    margin: auto;
    display: flex;
    padding: 50px 0px;
    align-items: center;
  }

  .item {
    font-size: 18px;
    line-height: 34.96px;
  }

  .value {
    font-size: 14px;
    line-height: 26.64px;
  }
}

@media only screen and (max-width: 1010px) {
  .headerA {
    font-size: 50px;
    line-height: 69.19px;
  }

  .text {
    font-size: 15px;
    line-height: 25.5px;
  }

  .accent {
    font-size: 16px;
  }

  .btn {
    font-size: 13px;
    line-height: 28.5px;
  }

  .headerB {
    font-size: 35px;
    line-height: 45.76px;
  }

  .hero {
    height: 430px;
  }

  /* .formInput {
    width: 65% !important;
   } */

  .testi > .headerB {
    text-align: center;
    font-size: 28px;
    line-height: 30.76px;
  }

  .tcard {
    width: 80%;
    border-radius: 5px;
    border: 10px bold rgb(100, 100, 224);
    background: linear-gradient(180deg, #bcc6df, #aac5f5);
    padding: 5px;
  }

  .tcard > .text {
    font-size: 14px;
    line-height: 22.5px;
  }

  .cimage {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
  .cimage > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .testi > .btn {
    font-size: 15px;
    font-weight: 400;
    line-height: 20.5px;
    padding: 4px;
  }

  .item {
    font-size: 15px;
    line-height: 34.96px;
  }

  .value {
    font-size: 11px;
    line-height: 26.64px;
  }
}

@media only screen and (max-width: 842px) {
  .headerA {
    font-size: 45px;
    line-height: 59.19px;
  }

  .text {
    font-size: 13px;
    line-height: 25.5px;
  }

  .accent {
    font-size: 15px;
  }

  .btn {
    font-size: 13px;
    line-height: 28.5px;
  }

  .headerB {
    font-size: 30px;
    line-height: 45.76px;
  }

  .service {
    width: 40%;
  }

  .hero {
    height: 380px;
  }

  .testi > .headerB {
    text-align: center;
    font-size: 25px;
    line-height: 20px;
  }

  .tcard {
    width: 80%;
    border-radius: 5px;
    border: 10px bold rgb(100, 100, 224);
    background: linear-gradient(180deg, #bcc6df, #aac5f5);
    padding: 5px;
  }

  .tcard > .text {
    font-size: 13px;
    line-height: 20.5px;
  }

  .cimage {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .cimage > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .testi > .btn {
    font-size: 15px;
    font-weight: 400;
    line-height: 20.5px;
    padding: 4px;
  }

  .tbg > img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 770px) {
  .headerA {
    font-size: 40px;
    line-height: 49.19px;
  }

  .text {
    font-size: 13px;
    line-height: 25.5px;
  }

  .accent {
    font-size: 15px;
  }

  .btn {
    font-size: 13px;
    line-height: 28.5px;
  }

  .headerB {
    font-size: 30px;
    line-height: 45.76px;
  }

  .service {
    width: 40%;
  }

  .testi {
    width: 70%;
  }
}

@media only screen and (max-width: 750px) {
  .headerA {
    font-size: 35px;
    line-height: 49.19px;
  }

  .hero,
  .about,
  .services,
  .stats,
  .projects,
  .exp,
  .testimonials,
  .footerB {
    width: 90%;
  }

  .about {
    width: 100%;
    display: block !important;
  }

  .htext,
  .aboutB,
  .servicesA,
  .servicesB,
  .exp1,
  .contactA,
  .contactB {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap !important;
    width: 100%;
  }

  .text {
    font-size: 13px;
    line-height: 25.5px;
  }

  .accent {
    font-size: 15px;
  }

  .btn {
    font-size: 13px;
    line-height: 28.5px;
  }

  .headerB {
    font-size: 30px;
    line-height: 45.76px;
  }

  .service {
    width: 40%;
  }

  .himage {
    display: none;
  }

  .htext {
    text-align: center;
    margin: 0 auto;
  }

  .btn {
    margin-right: auto;
    margin-left: auto;
  }

  .about,
  .services,
  .exp,
  .contact {
    flex-direction: column;
  }

  .aboutB,
  .exp {
    text-align: center;
  }

  .accent,
  .headerB,
  .text {
    text-align: center;
  }

  .servicesB {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .service {
    width: 80%;
  }

  .form {
    width: 98%;
  }

  .ccard {
    width: 100%;
  }

  textarea {
    width: 94% !important;
  }
}

@media only screen and (max-width: 590px) {
  .headerA {
    font-size: 30px;
    line-height: 49.19px;
  }

  .hero,
  .about,
  .services,
  .stats,
  .projects,
  .exp,
  .testimonials,
  .footerB {
    width: 90%;
    padding-top: 15px;
  }

  .about {
    width: 100%;
    display: block !important;
  }

  .htext,
  .aboutB,
  .servicesA,
  .servicesB,
  .exp1,
  .contactA,
  .contactB {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap !important;
    width: 100%;
  }

  .accent,
  .headerB,
  .text {
    text-align: center;
  }

  .slide {
    height: 450px;
  }

  .before-slide,
  .after-slide {
    display: none;
  }

  .slider {
    width: 90%;
  }

  .servicesB {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .service {
    width: 80%;
  }

  .form {
    width: 98%;
  }

  .ccard {
    width: 100%;
  }

  textarea {
    width: 94% !important;
  }
}

@media only screen and (max-width: 400px) {
  .service {
    width: 89%;
  }
}

@media only screen and (max-width: 300px) {
  .htext {
    width: 450px;
  }
}

/* Carousel.css */
.carousel {
  position: relative;
  width: 95% !important;
  padding: 15px;
  height: fit-content;
  min-height: 100px;
  overflow: hidden;
  margin: auto;
  text-align: center;
  background-color: #8caaf5;
  border-radius: 5px;
}

.ct {
  text-align: center;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.carousel-item.active {
  left: 0;
  opacity: 1;
}

.client {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  gap: 30px;
  text-align: center;
}

.button-container {
  margin-top: 10px;
  display: flex;
  justify-content: end;
}
.button-container button {
  border: none;
  padding: 3px 10px;
}
