* a {
    text-decoration: none;
}
.nav {
    display: flex;  
    height: 80px;
    align-items: center; 
    width: 100vw;
    /* max-width: 1300px; */
    margin: 0px auto;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 3;
    justify-content: space-around;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.logo{
    margin-right: auto;
}

.logo > img {
    width: auto;
    height: 60px;
}

.menuToggle {
    width: 0px;
    height: 0px;
    /* background-color: #2C5AA7; */
}

.navlinks {
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 40px;
    width: 50%;
    align-items: center;
}

.navlink {
    font-family: Inter;
    font-size: 18px;
    font-weight: 300;
    color: #2C5AA7;
    border: 5px solid #ffffff;
    padding: 3px 10px;
}


.navlink:hover {
    background-color: #95C1E6;
    border: 5px solid #95C1E6;
    border-radius: 5px;
}

.navlink.active {
    background-color: #2C5AA7;
    border-color: #2C5AA7;
    color: #ffffff;
    border-radius: 5px;
}

.search > img {
    width: 25px;
    height: auto;
}

@media only screen and (max-width: 650px) {
    .search {
        display: none;
    }

    .nav {
        flex-direction: column;
        justify-content: flex-start;
    }

    .logo {
        margin-right: 25vw
    }

    .menuToggle {
        height: 30px;
        width: 30px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 250px;
        margin-top: -50px;
    }

    .navlinks {
        flex-direction: column;
        padding-left: auto;
        padding-right: auto;
        gap: 15px;
        width: 100%;
        margin-top: 50px;
        height: fit-content;
        z-index: 2;
        background-color: #ffffff;
    }

    .navlinks.false {
        display: none;
    }

    .bar {
        width: 25px;
        height: 5px;
        background-color: #2C5AA7;
        transition: transform 0.3s ease;
    }
    
    .clicked:nth-child(1) {
        transform: rotate(45deg) translate(5px, 4px);
        transition: transform 0.3s ease;
    }
    
    .clicked:nth-child(2) {
        display: none;
        transition: transform 0.3s ease;
    }

    .clicked:nth-child(3) {
        transform: rotate(-45deg) translate(1px, 1px);
        transition: display 0.3s ease;
    }
    
}