.footer {
    display: flex;  
    flex-direction: column;
    width: 100vw;
    padding: 50px 0px;
    margin: 0px auto;
    background-color:#2C5AA7;
    color: #ffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    align-items: center;
    text-align: left;
}

.footerP {
    color: #ffff;
    margin-bottom: 20px;
}

.wc > a,.ftLink > a  {
    color: #ffff;
}

.ftLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.divider {
    width: 80vw;
    max-width: 700px;
    height: 1px;
    margin: 10px auto;
    background-color: #ffff;
}

.footerTop {
    display: flex;
    width: 95%;
    max-width: 1200px;
    margin: 0px auto;
    justify-content: space-between;
}

/* .footerTop > div {
    flex-basis: 25%;
} */

.ftOne {
    flex-basis: 40%;
}

.ftLinks {
    flex-basis: 15%;
}

.ftContact {
    flex-basis: 30%;
}

.textLogo {
    font-weight: 700;
    font-size: 30px;
    color: #ffff;
    margin-bottom: 20px;
    line-height: 25px;
}

.ftLogo > a {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.ftLogo > a > img {
    width: 150px;
    height: auto;
}

@media only screen and (max-width: 700px){
    .footerTop {
        flex-direction: column;
    }

    .footerTop > div {
        width: 80% !important;
        margin: 5px auto;
        /* align-items: center; */
    } 
}

@media only screen and (max-width: 450px){
    .ftLinks{
        gap: 20px;
    }
}