.mainForm {
    display: flex;
    width: 100vw;
    height: 100vh;
}


.formMain {
    display: flex;
    flex-direction: column;
    width: 98vw;
    max-width: 1200px;
    margin: auto;
    /* height: 80vh; */
    gap: 10px;
    /* justify-content: space-around; */
}

form {
   width: 85vw;
   max-width: 600px;
   margin: 10px auto;
   /* border: 1px solid #95c1e6; */
}

label, input {
    display: block;
    margin: 10px;
}

form > div > input{
    width: 90%;
    background-color: #95c1e6;
    border: 2px solid #95c1e6;
    border-radius: 5px;
    padding: 10px;
}

.button-container {
    text-align: center;
}

.button-container button {
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    background-color: #2c5aa7;
    color: #ffffff;
    font-size: 15px;
    box-shadow: none;
}

.formLink {
    text-align: center;
    margin: 5px auto;
}

.formTitle {
    font-size: 20px;
    font-weight: 700;
}

.formLink > a {
    text-decoration: none;
}

textarea {
    width: 95%;
    height: 500px;
    margin: auto;
}

.profileMain {
    width: 90vw;
    max-width: 1200px;
    margin: 5px auto;
    min-height: 70vh;
    height: fit-content;
}