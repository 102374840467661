* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.sMain {
  display: flex;
  width: 98vw;
  max-width: 1200px;
  margin: 20px auto;
  min-height: 70vh;
  max-height: fit-content;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sBanner {
  margin-bottom: 10px;
  color: #2c5aa7 !important;
}

.sIntro,
.sContent {
  font-size: 17px;
  width: 100%;
}

.sHeader,
.sHead {
  font-weight: 500;
  color: #2c5aa7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.sIntro {
  font-weight: 400;
}
.sText {
  font-weight: 300;
}

.sContent {
  font-size: 16px;
}

.serviceCard {
  width: 380px;
  height: 450px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.serviceCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.serviceCard img {
  width: 100%;
  height: 150px;
}

.serviceCardImage {
  width: 100%;
  height: auto;
  display: block;
}

.serviceCardContent {
  padding: 16px;
  height: fit-content;
}

.serviceCardTitle {
  font-size: 1.3em;
  color: #2c5aa7;
  margin-bottom: 8px;
}

.serviceCardDescription {
  font-size: 17px;
  color: #242424;
}

.serviceHeaderContainer {
  position: relative;
  text-align: start;
  color: white;
}

.serviceHeaderContainer img {
  width: 80vw;
  height: 100px;
  object-fit: cover;
}
.serviceHeaderContainer .serviceText {
  position: relative;
  color: #242424;
}

.serviceHeaderContainer .serviceText h1 {
  font-size: 25px;
  margin-bottom: 15px;
  color: #2c5aa7;
  text-align: center;
}

.sectionMargin {
  margin-bottom: 40px;
}

@media only screen and (max-width: 590px) {
  .sMain {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .sMain {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 300px) {
  .sMain {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
