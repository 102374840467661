@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

* {
    font-family: 'poppins', sans-serif;
    text-decoration: none;
    outline: none;
}


.container {
    max-width: 1400px;
    min-height: 100vh;
    margin: 0 auto;
}

::selection {
    background: #0000ff;
    color: #fff;
}



.about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 50px auto 0;
    gap: 30px;
}



.container .about-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: contain;
    border: 5px solid #ebeeef;
}

.about--content {
    width: 70%;
    height: 100%;
    text-align: center;
    
    
}

.about--Content h1 {
    color: #2C5AA7;
    font-size: 35px;
    margin-bottom: 10px;
}

.about--content p {
    font-size: 25px;
    line-height: 28px;
}

.flex-container {
    width: 90%;
    display: flex;
    justify-content: center; 
    align-items: center;     
    flex-direction: row; 
    flex-wrap: wrap;
    gap: 50px;
    background-color: #fff;
    margin: 0 auto;
}

.flex-item {
    background-color: #f9f9f9;
    box-shadow: 0 2.5px 5px rgba(0, 0, 0, 0.1); 
    padding: 10px;          
    border-radius: 5px;     
    cursor: pointer;           
    width: 250px;              
    text-align: center;
    height: 150px;
    transition: background-color 0.3s ease, border 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);  
}

.flex-item h2 {
    color: #2C5AA7;
    font-size: 20px;          
    margin-bottom: 10px;  
}

.flex-item p {
    font-size: 15px;            
    line-height: 18px;        
    
}

.flex-item ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;            
    line-height: 15px;        
    
}

.flex-item ul li {
    margin-bottom: 5px;      
}


.flex-item:hover {
    background-color: #e0f7fa;
    box-shadow: 0 3.75px 7.5px rgba(0, 0, 0, 0.15); 
    border: 1px solid #2196F3;
}


@media only screen and (max-width: 700px) {
  
    .about img {
        display: none;
    }

    .container {
        margin: 10px;
        
    }
    .about {
        
        width: 80%;
        text-align: center;
    }
    .flex-item {
        width: 80%;
        margin-left: 0;
        
    }
    .flex-container{
        
        width: 100%;
        align-items: center;
    }
    .flex-item p, .flex-item ul {
        font-family: 'poppins', sans-serif;
    }
}