.legal-page {
  padding: 20px;
  max-width: 1200px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333333;
}

.legal-page h1 {
  color: #2c5aa7;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.legal-page h2 {
  color: #2c5aa7;
  text-align: start;
}

.legal-page p {
  color: #444444;
  font-size: 1.1em;
}

@media only screen and (max-width: 590px) {
  .legal-page {
    width: 90%;
    padding: 20px 0px; 
  }
}

@media only screen and (max-width: 400px) {
  .legal-page {
    width: 90%;
    padding: 20px 0px; 
  }
}

@media only screen and (max-width: 300px) {
  .legal-page {
    width: 90%;
    padding: 20px 0px;
  }
}
